import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Auth.css';
import axios from 'axios';
import config from '../config/config';
import { toast } from 'react-toastify';

const SignIn = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${config.BACKEND_HOST_PORT}${config.ULTRA_ADMIN_SIGNIN}`, {
        email,
        password
      });

      if (response.status === 200) {
        localStorage.setItem('authToken', response.data.token);
        setIsAuthenticated(true);
        toast.success('Sign in successful');
        navigate('/download');
      } else {
        toast.error('Sign in failed');
      }
    } catch (error) {
      toast.error('Error signing in');
      console.error('Error signing in:', error);
    }
  };

  return (
    
    <div className='au_ths'>
    <div className="auth-container">
      
        <div className='authths'>
        <h2>Sign In</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="auth-button">Sign In</button>
        </form>
        <p>
          Don't have an account? <a href="/signup">Sign Up</a>
        </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
