import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './MetaTagsPage.css';
import config from '../../config/config';
import { formatToIST } from '../../utils/dateUtils';

const pages = [
    { name: 'Home', value: 'home-four' },
    { name: 'About', value: 'about' },
    { name: 'Career', value: 'career' },
    { name: 'Blog', value: 'blog' },
    { name: 'Enhanced Enterprises', value: 'Enhanced-Enterprises' },
    { name: 'Contact', value: 'contact' },
    { name: 'Privacy Policy', value: 'privacypolicy' },
    { name: 'Expo Lead Generate', value: 'online-expo' },
    { name: 'Online expo', value: 'expo-login' },
];

const MetaTagForm = () => {
    const [selectedPage, setSelectedPage] = useState('home');
    const [metaTag, setMetaTag] = useState({
        title: '',
        description: '',
        keywords: '',
        ogUrl: '',
        ogType: '',
        ogTitle: '',
        ogDescription: '',
        ogImage: '',
        // ogImageAlt: '',
        // twitterDomain: '',
        twitterCard: '',
        // twitterUrl: '',
        twitterTitle: '',
        twitterDescription: '',
        twitterImage: '',
        // twitterImageAlt: '',
        twitterSite: '',
        canonicalUrl: '',
        createdAt: '',
        updatedAt: '',
    });

    useEffect(() => {
        const fetchMetaTag = async () => {
            try {
                const response = await axios.get(`${config.BACKEND_HOST_PORT}${config.META_TAGS}?page=${selectedPage}`);

                if (response.data.status) {
                    setMetaTag({
                        ...response.data.data,
                        createdAt: formatToIST(response.data.data.createdAt),
                        updatedAt: formatToIST(response.data.data.updatedAt),
                    });
                } else {
                    setMetaTag({
                        title: '',
                        description: '',
                        keywords: '',
                        ogUrl: '',
                        ogType: '',
                        ogTitle: '',
                        ogDescription: '',
                        ogImage: '',
                        // ogImageAlt: '',
                        // twitterDomain: '',
                        twitterCard: '',
                        // twitterUrl: '',
                        twitterTitle: '',
                        twitterDescription: '',
                        twitterImage: '',
                        // twitterImageAlt: '',
                        twitterSite: '',
                        canonicalUrl: '',
                        createdAt: '',
                        updatedAt: '',
                    });
                }
            } catch (error) {
                console.error('Error fetching meta tag:', error);
            }
        };

        fetchMetaTag();
    }, [selectedPage]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMetaTag({ ...metaTag, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${config.BACKEND_HOST_PORT}${config.META_TAGS}`, { ...metaTag, page: selectedPage });
            
            if (response.data.status) {
                toast.success(response.data.message);

            } else {
                toast.warning(response.data.message);

            }
        } catch (error) {
            console.error('Error submitting meta tag:', error);
            if (error.response && error.response.status === 400) {
                toast.error('Meta tag for this page already exists');
            } else {
                toast.error('An error occurred. Please try again later.');
            }
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.put(`${config.BACKEND_HOST_PORT}${config.META_TAGS}/${metaTag._id}`, { ...metaTag, page: selectedPage });
            toast.success(response.data.message);
        } catch (error) {
            console.error('Error updating meta tag:', error);
            toast.error('An error occurred. Please try again later.');
        }
    };

    return (
        <div className='admin_main'>
            <div className="meta-tag-form">
                <div className="tabs">
                    {pages.map((page) => (
                        <button
                            key={page.value}
                            className={`tab ${selectedPage === page.value ? 'active' : ''}`}
                            onClick={() => setSelectedPage(page.value)}
                        >
                            {page.name}
                        </button>
                    ))}
                </div>
                <form onSubmit={metaTag._id ? handleUpdate : handleSubmit}>
                    {Object.keys(metaTag)
                        .filter(key => key !== 'createdAt' && key !== 'updatedAt' && key !== '__v' && key !== '_id' && key !== 'page') // Exclude '_id' and 'page' fields
                        .map((key) => (
                            <div key={key} className="form-group">
                                <label>{key}</label>
                                {key === 'createdAt' || key === 'updatedAt' ? (
                                    <input
                                        type="text"
                                        name={key}
                                        value={metaTag[key]}
                                        readOnly
                                        className="read-only"
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        name={key}
                                        value={metaTag[key]}
                                        onChange={handleInputChange}
                                        className={metaTag[key] === '' ? 'empty' : ''}
                                    />
                                )}
                            </div>
                        ))}
                    <button type="submit">{metaTag._id ? 'Update' : 'Submit'}</button>
                </form>

            </div>
        </div>
    );
};

export default MetaTagForm;
