import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../BlogListPage.css';
import config from '../../config/config';

const NewsList = () => {
    const [anouncementList, setAnouncementList] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${config.BACKEND_HOST_PORT}${config.NEWS_LIST}`);
                console.log(response.data.data, ":::::::::::::::");
                setAnouncementList(response.data.data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };
        fetchBlogs();
    }, [config]);
    const cleanHtmlString = (str) => {
        return str.replace(/\\"/g, '');
    };
    const getFirstTagContent = (htmlString) => {
        const cleanHtml = htmlString.replace(/\\/g, ''); // Remove backslashes
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = cleanHtml;
        const firstElement = tempDiv.firstElementChild;
    
        if (firstElement) {
            let truncatedContent = firstElement.textContent.trim().substring(0, 30);
            if (firstElement.textContent.trim().length > 30) {
                truncatedContent += '...';
            }
            return truncatedContent;
        }
    
        return '';
    };
    
    
    return (
        <div className="blog-list-container">
            <h1>News List</h1>
            <div className="blog-list">
                
            {anouncementList.length === 0 ? (
                <p>No Records Found</p>
            ) : (
                anouncementList.map((blog, index) => (
                    <div key={index} className="blog-card">
                        <img src={blog.image} alt={blog.title} className="blog-image" />
                        <div className="blog-content">
                            <h2 className="blog-title">{blog.title}</h2>
                            <div className="head-field" dangerouslySetInnerHTML={{ __html: getFirstTagContent(blog.ckEditerContent) }} />
                            <a href={`/viewNewsPage/${blog._id}`} className="blog-link">
                                Edit or View
                            </a>
                        </div>
                    </div>
                ))
            )}
            </div>
        </div>
    );
};

export default NewsList;
