import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './createHrCareerPortal.css';
import config from '../../config/config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const JobManagement = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobTitle, setJobTitle] = useState('');
  const [jobDescription, setJobDescription] = useState('');

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const response = await axios.get(`${config.BACKEND_HOST_PORT}${config.VIEW_JOBS}`);
    
      if (response.data.status) {
        setJobs(response.data.data);
      } else {
        toast.success(response.data.message);
      }
    } catch (error) {
    
      toast.success('Failed to fetch jobs.');
    }
  };

  const handleEdit = (job) => {
    
    setSelectedJob(job);
    setJobTitle(job.jobTitle);
    setJobDescription(job.jobDescription);
  };

  const handleDelete = async (id) => {
   
    try {
      const response = await axios.post(`${config.BACKEND_HOST_PORT}${config.DELETE_JOBS}/${id}`);
     
      if (response.data.status) {
        toast.success(response.data.message);
        fetchJobs();
      } else {
        toast.success(response.data.message);
      }
    } catch (error) {
     
      toast.success('Failed to delete job.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedJob) {
    
      try {
        const response = await axios.post(`${config.BACKEND_HOST_PORT}${config.EDIT_JOB_DETAILS}/${selectedJob._id}`, {
          jobTitle,
          jobDescription
        });
       
        if (response.data.status) {
          toast.success(response.data.message);
          fetchJobs();
          setSelectedJob(null);
          setJobTitle('');
          setJobDescription('');
        } else {
          toast.success(response.data.message);
        }
      } catch (error) {
       
        toast.success('Failed to update job.');
      }
    } else {
      toast.success('No job selected for editing.');
    }
  };

  return (
    <div className="job-management">
    <h1>Job Management</h1>
    <div className="job-list">
      <h2>Job List</h2>
      <ul>
        {jobs.map((job) => (
          <li key={job._id}>
            <h3>{job.jobTitle}</h3>
            <button onClick={() => handleEdit(job)}>Edit</button>
            <button onClick={() => handleDelete(job._id)}>Delete</button>
            {selectedJob && selectedJob._id === job._id && (
              <div className="job-edit-dropdown">
                <h2>Edit Job</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="jobTitle">Job Title:</label>
                    <input
                      type="text"
                      id="jobTitle"
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="jobDescription">Job Description:</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={jobDescription}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setJobDescription(data);
                      }}
                    />
                  </div>
                  <button type="submit" className="submit-btn">Update Job</button>
                </form>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  </div>
  );
};

export default JobManagement;
