import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '.././ViewBlogPage.css';
import config from '../../config/config';
import { useParams, useNavigate } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['link', 'image'],
        ['clean']
    ],
};

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'align',
    'link', 'image'
];

const ViewAnouncementPage = () => {

    const handleChange = (html) => {
        setEditorHtml(html);
    };
    const navigate = useNavigate();
    const { id } = useParams();

    const [blog, setBlog] = useState({
        // fields: [{ subTitle: '', subDescription: '' }],
        image: null,
        title: '',
        description: '',
        keywords: '',
        ogUrl: '',
        ogType: '',
        ogTitle: '',
        ogDescription: '',
        ogImage: '',
        // ogImageAlt: '',
        // twitterDomain: '',
        // twitterUrl: '',
        twitterTitle: '',
        // twitterCard: '',
        twitterDescription: '',
        twitterImage: '',
        twitterImageAlt: '',
        twitterSite: '',
        canonicalUrl: ''
    });

    const [ogImageUrl, setOgImageUrl] = useState(null);
    const [removedHeadFields, setRemovedHeadFields] = useState([]);
    const [removedFields, setRemovedFields] = useState([]);
    const [editorHtml, setEditorHtml] = useState('');
    // const cleanHtmlString = (str) => {
    //     return str.replace(/\\"/g, '');
    // };
    const cleanHtmlString = (str) => {
        console.log('str---', str);
        return str ? str.replace(/^"|"$/g, '').replace(/\\/g, '') : '';
    };

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await axios.post(`${config.BACKEND_HOST_PORT}${config.ANNOUNCEMENT_BLOG_EDIT_BY_ID}`, { blogId: id });
                console.log('response.data.data----', response.data);
                setBlog(response.data.data);

                const rawHtml = response.data.data.ckEditerContent;
                const decodedHtml = cleanHtmlString(rawHtml);
                console.log(decodedHtml, "{}{}{}{}{}{");
                setEditorHtml(decodedHtml);

            } catch (error) {
                console.error('Error fetching blog:', error);
                toast.error('Error fetching blog post. Please try again later.');
            }
        };

        fetchBlog();
    }, [id, config]);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setOgImageUrl(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setBlog({ ...blog, image: reader.result });
        };
        reader.readAsDataURL(file);
    };

    // const handleAddField = (fieldType) => {
    //     setBlog({
    //         ...blog,
    //         [fieldType]: [...blog[fieldType], fieldType === 'headFields' ? { title: '', description: '' } : { subTitle: '', subDescription: '' }],
    //     });
    // };

    // const handleRemoveField = (fieldType, index) => {
    //     const newFields = blog[fieldType].filter((_, i) => i !== index);

    //     if (fieldType === 'headFields') {
    //         setRemovedHeadFields([...removedHeadFields, blog[fieldType][index]]);
    //     } else {
    //         setRemovedFields([...removedFields, blog[fieldType][index]]);
    //     }

    //     setBlog({ ...blog, [fieldType]: newFields });
    // };

    const handleInputChange = (fieldType, index, field, value) => {
        const newFields = [...blog[fieldType]];
        newFields[index][field] = value;
        setBlog({ ...blog, [fieldType]: newFields });
    };

    const handleFieldChange = (field, value) => {
        setBlog({ ...blog, [field]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!blog.image) {
            toast.error('Please upload an image.');
            return;
        }

        // if (blog.fields.length === 0) {
        //     toast.error('Please fill out all fields.');
        //     return;
        // }

        const formData = new FormData();
        if (ogImageUrl) {
            formData.append('files', ogImageUrl);
        }

        // formData.append('headFields', JSON.stringify(blog.headFields));
        // formData.append('fields', JSON.stringify(blog.fields));
        formData.append('ckEditerContent', JSON.stringify(editorHtml));


        Object.keys(blog).forEach((key) => {
            if (key !== 'ckEditerContent') {
                formData.append(key, blog[key]);
            }
        });

        try {
            const response = await axios.post(`${config.BACKEND_HOST_PORT}${config.ANOUNCEMENT_EDIT}/${id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.data.status) {
                toast.success(response.data.message);
            } else {
                toast.error('Failed to update blog post. Please try again later.');
            }
        } catch (error) {
            console.error('Error updating blog post:', error);
            toast.error('An error occurred. Please try again later.');
        }
    };


    const handleDelete = async () => {
        try {
            const response = await axios.post(`${config.BACKEND_HOST_PORT}${config.ANOUNCEMENT_DELETE}/${id}`);
            if (response.data.status) {
                toast.success('Blog post deleted successfully!');
                navigate('/anouncementList');
            } else {
                toast.error('Failed to delete blog post. Please try again later.');
            }
        } catch (error) {
            console.error('Error deleting blog post:', error);
            toast.error('An error occurred. Please try again later.');
        }
    };

    return (
        <div className='admin_main'>
            <div className="blog-edit-container">
                <ToastContainer />
                <h1>Edit News Post</h1>
                <form onSubmit={handleSubmit}>
                    <div className="form-field">
                        <label>Upload Image</label>
                        <input type="file" onChange={handleImageUpload} />
                        {blog.image && <img src={blog.image} alt="Preview" className="image-preview" />}
                    </div>

                    {/* {blog.headFields.map((field, index) => (
                    <div key={index} className="form-field-group">
                        <div className="form-field">
                            <label>Head Title {index + 1}</label>
                            <input
                                type="text"
                                value={field.title}
                                onChange={(e) => handleInputChange('headFields', index, 'title', e.target.value)}
                            />
                        </div>
                        <div className="form-field">
                            <label>Head Description {index + 1}</label>
                            <textarea
                                value={field.description}
                                onChange={(e) => handleInputChange('headFields', index, 'description', e.target.value)}
                            />
                        </div>
                        <button type="button" onClick={() => handleRemoveField('headFields', index)}>Remove</button>
                    </div>
                ))}
                <button type="button" onClick={() => handleAddField('headFields')}>Add More Head Title and Description</button>
                <br />
                {blog.fields.map((field, index) => (
                    <div key={index} className="form-field-group">
                        <div className="form-field">
                            <label>Subtitle {index + 1}</label>
                            <input
                                type="text"
                                value={field.subTitle}
                                onChange={(e) => handleInputChange('fields', index, 'subTitle', e.target.value)}
                            />
                        </div>
                        <div className="form-field">
                            <label>Sub Description {index + 1}</label>
                            <textarea
                                value={field.subDescription}
                                onChange={(e) => handleInputChange('fields', index, 'subDescription', e.target.value)}
                            />
                        </div>
                        <button type="button" onClick={() => handleRemoveField('fields', index)}>Remove</button>
                    </div>
                ))}
                <button type="button" onClick={() => handleAddField('fields')}>Add More Subtitle and Sub Description</button>
                <br /> */}


                    <div className="editor-wrapper">
                        <ReactQuill
                            value={editorHtml}
                            onChange={handleChange}
                            modules={modules}
                            formats={formats}
                        />
                        {/* <div className="html-viewer">
        <h2>HTML Output</h2>
        <pre>{editorHtml}</pre>
      </div> */}
                    </div>



                    <div className="form-field">
                        <label>Title</label>
                        <input type="text" value={blog.title} onChange={(e) => handleFieldChange('title', e.target.value)} />
                    </div>
                    <div className="form-field">
                        <label>Description</label>
                        <textarea value={blog.description} onChange={(e) => handleFieldChange('description', e.target.value)} />
                    </div>
                    <div className="form-field">
                        <label>Keywords</label>
                        <input type="text" value={blog.keywords} onChange={(e) => handleFieldChange('keywords', e.target.value)} />
                    </div>
                    <div className="form-field">
                        <label>OG URL</label>
                        <input type="text" value={blog.ogUrl} onChange={(e) => handleFieldChange('ogUrl', e.target.value)} />
                    </div>
                    <div className="form-field">
                        <label>OG Type</label>
                        <input type="text" value={blog.ogType} onChange={(e) => handleFieldChange('ogType', e.target.value)} />
                    </div>
                    <div className="form-field">
                        <label>OG Title</label>
                        <input type="text" value={blog.ogTitle} onChange={(e) => handleFieldChange('ogTitle', e.target.value)} />
                    </div>
                    <div className="form-field">
                        <label>OG Description</label>
                        <textarea value={blog.ogDescription} onChange={(e) => handleFieldChange('ogDescription', e.target.value)} />
                    </div>
                    <div className="form-field">
                        <label>OG Image</label>
                        <input type="text" value={blog.ogImage} onChange={(e) => handleFieldChange('ogImage', e.target.value)} />
                    </div>
                    {/* <div className="form-field">
                        <label>OG Image Alt</label>
                        <input type="text" value={blog.ogImageAlt} onChange={(e) => handleFieldChange('ogImageAlt', e.target.value)} />
                    </div>
                    <div className="form-field">
                        <label>Twitter Domain</label>
                        <input type="text" value={blog.twitterDomain} onChange={(e) => handleFieldChange('twitterDomain', e.target.value)} />
                    </div>
                    <div className="form-field">
                        <label>Twitter URL</label>
                        <input type="text" value={blog.twitterUrl} onChange={(e) => handleFieldChange('twitterUrl', e.target.value)} />
                    </div> */}
                    <div className="form-field">
                        <label>Twitter Title</label>
                        <input type="text" value={blog.twitterTitle} onChange={(e) => handleFieldChange('twitterTitle', e.target.value)} />
                    </div>
                    {/* <div className="form-field">
                        <label>Twitter Card</label>
                        <textarea value={blog.twitterCard} onChange={(e) => handleFieldChange('twitterCard', e.target.value)} />
                    </div> */}
                    <div className="form-field">
                        <label>Twitter Description</label>
                        <textarea value={blog.twitterDescription} onChange={(e) => handleFieldChange('twitterDescription', e.target.value)} />
                    </div>
                    <div className="form-field">
                        <label>Twitter Image</label>
                        <input type="text" value={blog.twitterImage} onChange={(e) => handleFieldChange('twitterImage', e.target.value)} />
                    </div>
                    <div className="form-field">
                        <label>Twitter Card</label>
                        <input type="text" value={blog.twitterImageAlt} onChange={(e) => handleFieldChange('twitterImageAlt', e.target.value)} />
                    </div>
                    <div className="form-field">
                        <label>Twitter Site</label>
                        <input type="text" value={blog.twitterSite} onChange={(e) => handleFieldChange('twitterSite', e.target.value)} />
                    </div>
                    <div className="form-field">
                        <label>Canonical URL</label>
                        <input type="text" value={blog.canonicalUrl} onChange={(e) => handleFieldChange('canonicalUrl', e.target.value)} />
                    </div>

                    <button type="submit">Submit</button>
                    <button type="button" onClick={handleDelete} className="delete-button">Delete Blog Post</button>
                </form>
            </div>
        </div>
    );
};

export default ViewAnouncementPage;
