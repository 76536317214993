import React from 'react';
import './Modal.css';

const Modal = ({ supportReply, setSupportReply, closeModal, handleSubmitReply }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Support Team Reply</h2>
                <textarea
                    value={supportReply}
                    onChange={(e) => setSupportReply(e.target.value)}
                    placeholder="Enter your reply"
                    className='text-area'
                />
                <div className="modal-actions">
                    <button onClick={handleSubmitReply} className="btns">Submit</button>
                    <button onClick={closeModal} className="btns">Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
