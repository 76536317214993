import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LeadsDownload from './pages/LeadsDownload';
import SignIn from './pages/SignIn';
import VideoUpload from './pages/VideoUpload';
import SideNav from './pages/SideNav';
import BlogPage from './pages/BlogPage';
import 'bootstrap/dist/css/bootstrap.min.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import BlogListPage from './pages/BlogList ';
import ViewBlogPage from './pages/ViewBlogPage';
import MetaTagsPage from './pages/meta/MetaTagsPage';
import Anouncement from './pages/Anouncements/Anouncement';
import AnouncementList from './pages/Anouncements/AnouncementList';
import ViewAnouncementPage from './pages/Anouncements/AnouncementView';
import News from './pages/News/News';
import NewsList from './pages/News/NewsList';
import NewsView from './pages/News/NewsView';
import LiveExpo from './pages/LiveExpo/LiveMain';
import { NameProvider } from './pages/LiveExpo/NameContext';
// import SignUp from './pages/SignUp';
import CreateChatRoom from './pages/CreateChatRoom'
import JobPortal from './pages/UltraproHRCareerPortal/createHrCareerPortal';
import JobManagement from './pages/UltraproHRCareerPortal/JobManagement';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('authToken') ? true : false);
  const [news,setNews] =useState(false);

  const hamburger = () =>{
    setNews(!news)
  }

  return (
    <div className="App">
      <ToastContainer />
      <NameProvider>
      <Router>
        <SideNav isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} burger={news} />
        <div className='ham' onClick={hamburger}>
          <img src='images/burgermenu.svg' alt='burgermenu'></img>
        </div>
        <Routes>
          <Route path="/download" element={isAuthenticated ? <LeadsDownload /> : <Navigate to="/signin" />} />
          <Route path="/blogList" element={isAuthenticated ? <BlogListPage /> : <Navigate to="/signin" />} />
          <Route path="/videoUpload" element={isAuthenticated ? <VideoUpload /> : <Navigate to="/signin" />} />
          <Route path="/blogPost" element={isAuthenticated ? <BlogPage /> : <Navigate to="/signin" />} />
          <Route path="/viewBlogPage/:id" element={isAuthenticated ? <ViewBlogPage /> : <Navigate to="/signin" />} />
          <Route path="/metaTagsPage/" element={isAuthenticated ? <MetaTagsPage /> : <Navigate to="/signin" />} />
          <Route path="/signin" element={<SignIn setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/anouncement" element={isAuthenticated ? <Anouncement /> : <Navigate to="/signin" />} />
          <Route path="/anouncementList" element={isAuthenticated ? <AnouncementList /> : <Navigate to="/signin" />}/>
          <Route path="/viewAnouncementPage/:id" element={isAuthenticated ? <ViewAnouncementPage /> : <Navigate to="/signin" />}/>
          <Route path="/news" element={isAuthenticated ? <News /> : <Navigate to="/signin" />}/>
          <Route path="/newsList"  element={isAuthenticated ? <NewsList /> : <Navigate to="/signin" />}/>
          <Route path="/viewNewsPage/:id"  element={isAuthenticated ? <NewsView /> : <Navigate to="/signin" />}/>
         
          <Route path="/liveExpo"  element={isAuthenticated ? <LiveExpo /> : <Navigate to="/signin" />}/>
          <Route path="/createChatRoom"  element={isAuthenticated ? <CreateChatRoom /> : <Navigate to="/signin" />}/>
          <Route path="/jobPortal"  element={isAuthenticated ? <JobPortal /> : <Navigate to="/signin" />}/>
          <Route path="/jobList"  element={isAuthenticated ? <JobManagement /> : <Navigate to="/signin" />}/>
          
          
          {/* <Route path="/signup" element={<SignUp />} /> */}
          <Route path="/" element={<SignIn setIsAuthenticated={setIsAuthenticated} />} />
        </Routes>
      </Router>
      </NameProvider>
    </div>
  );
}

export default App;
