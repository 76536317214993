const config = {
    BACKEND_HOST_PORT: 'https://backend.ultrapro.info',
    DOWNDLOAD_LEAD_SHEET: "/ultraAdminRouter/downloadLeadAsExcelSheet",
    SIGNUP: '/login',
    ULTRA_ADMIN_SIGNUP: "/ultraAdminRouter/signup",
    ULTRA_ADMIN_SIGNIN: "/ultraAdminRouter/login",
    ULTRA_EXPO_HISTORY: "/ultraAdminRouter/historyOfLead",
    SUPPORT_REPLY: "/ultraAdminRouter/updateSupportReply",
    VIDEO_UPLOAD: "/ultraAdminRouter/videoUpload",
    VIDEO_LINK: "/ultraAdminRouter/videoLinks",


    // BLOG
    BLOG_UPLOAD: '/blog/createBlog',
    BLOG_LIST: '/blog/blogList',
    BLOG_EDIT: "/blog/updateBlog",
    BLOG_DELETE: "/blog/deleteBlogs",
    ULTRA_PRO_SPECIFIC_BOLG_ID: '/blog/blogSpecificList',
    BLOG_EDIT_BY_ID: '/blog/blogSpecificId',
    // ANNOUNCEMENT
    ANOUNCEMENT: "/blog/createAnnouncement",
    ANOUNCEMENTLIST: "/blog/announcementList",
    ANOUNCEMENT_EDIT: '/blog/updateAnnouncement',
    ANOUNCEMENT_DELETE: "/blog/deleteAnnouncement",
    ULTRA_PRO_SPECIFIC_ANOUNCEMENT_ID: '/blog/announcementSpecificList',
    ANNOUNCEMENT_BLOG_EDIT_BY_ID: '/blog/announcementBlogSpecificId',

    // NEWS
    NEWS: '/blog/createBlogNews',
    NEWS_LIST: "/blog/newsBlogList",
    NEWS_EDIT: "/blog/updateBlogNews",
    NEWS_DELETE: "/blog/deleteBlogNews",
    ULTRA_PRO_SPECIFIC_NEWS_ID: '/blog/blogNewsSpecificList',
    NEWS_BLOG_EDIT_BY_ID: '/blog/newsSpecificList',

    VIDEO_DELETE: "/ultraAdminRouter/deleteVideo",
    META_TAGS: "/metaTagRouter/meta-tag",

    // CHAT ROOMS
    CREATE_CHAT_ROOMS: '/chatRooms/createChatRoom',
    CHAT_LIST_ROOM: '/chatRooms/chatRoomList',

    // HR JO PORTAL
    HR_JOB_PORTAL_CREATE: '/ultraproCareer/createJob',
    VIEW_JOBS: '/ultraproCareer/jobList',
    DELETE_JOBS: '/ultraproCareer/deleteJob',
    EDIT_JOB_DETAILS:"/ultraproCareer/editJob"
}

export default config;