import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config/config';


const CreateChatRoom = () => {
  const [roomName, setRoomName] = useState('');
  const [createdRoomId, setCreatedRoomId] = useState('');
  const [chatRoomNameList, setChatRoomNameList] = useState([]);

  const createRoom = async () => {
    try {

      const response = await axios.post(`${config.BACKEND_HOST_PORT}${config.CREATE_CHAT_ROOMS}`, { name: roomName });

      if (response.data.status) {
        setCreatedRoomId(response.data.chatRoomId);
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      alert('Error creating chat room');
      console.error(error);
    }
  };

  const chatRoomList = async () => {
    try {
      const response = await axios.post(`${config.BACKEND_HOST_PORT}${config.CHAT_LIST_ROOM}`);
      setChatRoomNameList(response.data.data);

    } catch (error) {

    }
  }

  useEffect(() => {
    chatRoomList();
  }, []);

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-center">Create Chat Room</h3>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={roomName}
                  onChange={(e) => setRoomName(e.target.value)}
                  placeholder="Chat Room Name"
                />
              </div>
              <button className="btn btn-primary btn-block mt-3" onClick={createRoom}>
                Create Room
              </button>
              {createdRoomId && (
                <div className="alert alert-success mt-3">
                  Chat Room ID: {createdRoomId}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title text-center">Chat Room List</h3>
                {chatRoomNameList && chatRoomNameList.length > 0 ? (
                  chatRoomNameList.map((data, index) => (
                    <div key={index}>
                      <div>{data.name}</div>
                      <div>{data.chatRoomId}</div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">No chat rooms available</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default CreateChatRoom;
