import React, { useState, useEffect } from 'react';
import './Auth.css';
import config from "../config/config";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const VideoUpload = () => {
  const [videoLink, setVideoLink] = useState('');
  const [uploadedVideo, setUploadedVideo] = useState([]);

  const navigate = useNavigate();


  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`${config.BACKEND_HOST_PORT}${config.VIDEO_LINK}`);
        setUploadedVideo(response.data);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (videoLink) {
      fetch(`${config.BACKEND_HOST_PORT}${config.VIDEO_UPLOAD}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ videoUrl: videoLink })
      })
        .then(response => response.json())
        .then(newVideoLink => {
          setUploadedVideo([...uploadedVideo, newVideoLink]);
          setVideoLink('');
        })
        .catch(error => console.error('Error saving video link:', error));
    }
  };

  const getEmbedLink = (link) => {
    if (!link) return '';

    let embedLink = '';
    const youtubeMatch = link.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    if (youtubeMatch) {
      embedLink = `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    }
    return embedLink;
  };


  const handleDelete = async (id) => {
    try {

      const response = await axios.post(`${config.BACKEND_HOST_PORT}${config.VIDEO_DELETE}/${id}`);
      if (response.data.status) {
        toast.success(response.data.message);
        navigate("/videoUpload");
      } else {
        toast.error('Failed to delete blog post. Please try again later.');
      }
    } catch (error) {
      console.error('Error deleting blog post:', error);
      toast.error('An error occurred. Please try again later.');
    }
  };


  return (
    <div className='admin_main'>
    <div className="admin-panel">
      <ToastContainer />
      <h1>Admin Panel</h1>

      <form onSubmit={handleSubmit}>
        <label htmlFor="videoLink">Video Link:</label>
        <input
          type="text"
          id="videoLink"
          name="videoLink"
          value={videoLink}
          onChange={(e) => setVideoLink(e.target.value)}
          required
        />
        <div className="buttons">
          <button type="submit">Submit</button>
          <button type="reset" onClick={() => setVideoLink('')}>Clear</button>
        </div>
      </form>

      <h2>Uploaded Videos</h2>
      <div>
        {uploadedVideo.length > 0 ? (
          <div className='vidos_liv'>
            {uploadedVideo.map((video, index) => (
              <>
                <iframe
                  key={index}
                  width="800"
                  height="450"
                  src={getEmbedLink(video.videoUrl)}
                  title={`video-${index}`}
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <button type="reset" onClick={() => handleDelete(video._id)}>Clear</button>
              </>
            ))}
          </div>
        ) : (
          <p>No videos uploaded yet.</p>
        )}
      </div>
    </div>
    </div>
  );
};

export default VideoUpload;
