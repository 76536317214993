import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SideNav.css';
import { NameContext } from './LiveExpo/NameContext';

const SideNav = ({ isAuthenticated, setIsAuthenticated, burger }) => {
  const { isNameSet } = useContext(NameContext);

  console.log(isNameSet, "{}}{}");
  const Burgur = burger
  const navigate = useNavigate();

  const handleSignOut = () => {
    // Clear any authentication tokens or user data
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
    navigate('/signin');
  };
  const [activeItem, setActiveItem] = useState(null); // State to track active item

  const handleItemClick = (item) => {
    setActiveItem(item);
  };
  return (
    <>
      {isNameSet ? (
        ""
      ) : (

        <div className={Burgur ? "soh sidenav" : "sidenav"}>
          <div className="sidenav-header">Admin Panel</div>
          <Link to="/blogPost" className={activeItem === '1' ? 'active' : ''} onClick={() => handleItemClick('1')}>Blogs</Link>
          <Link to="/blogList" className={activeItem === '2' ? 'active' : ''} onClick={() => handleItemClick('2')}>Blog List</Link>
          <Link to="/videoUpload" className={activeItem === '3' ? 'active' : ''} onClick={() => handleItemClick('3')}>Ultrapro Expo Video</Link>
          <Link to="/metaTagsPage" className={activeItem === '4' ? 'active' : ''} onClick={() => handleItemClick('4')}>Meta Tags</Link>
          <Link to="/anouncement" className={activeItem === '5' ? 'active' : ''} onClick={() => handleItemClick('5')}>Anouncement</Link>
          <Link to="/anouncementList" className={activeItem === '6' ? 'active' : ''} onClick={() => handleItemClick('6')}>Anouncement List</Link>
          <Link to="/news" className={activeItem === '7' ? 'active' : ''} onClick={() => handleItemClick('7')}>News</Link>
          <Link to="/newsList" className={activeItem === '8' ? 'active' : ''} onClick={() => handleItemClick('8')}>News List</Link>
          <Link to="/CreateChatRoom" className={activeItem === '10' ? 'active' : ''} onClick={() => handleItemClick('10')}>Chat Room</Link>
          <Link to="/liveExpo" className={activeItem === '11' ? 'active' : ''} onClick={() => handleItemClick('11')}>Live Expo</Link>
          <Link to="/jobPortal" className={activeItem === '12' ? 'active' : ''} onClick={() => handleItemClick('12')}>Post Job</Link>
          <Link to="/jobList" className={activeItem === '13' ? 'active' : ''} onClick={() => handleItemClick('13')}>Edit Job Or View Job</Link>

          {isAuthenticated && <Link to="/download" className={activeItem === 'item9' ? 'active' : ''} onClick={() => handleItemClick('9')}>Download</Link>}
          {isAuthenticated && <button className="signout-button" onClick={handleSignOut}>Sign Out</button>}
        </div>
      )}
    </>
  );
};

export default SideNav;
