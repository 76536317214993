// src/JobPortal.js
import React, { useState } from 'react';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './createHrCareerPortal.css';
import config from '../../config/config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const JobPortal = () => {
    const [jobTitle, setTitle] = useState('');
    const [jobDescription, setDescription] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const jobData = { jobTitle, jobDescription };

        try {
            const response = await axios.post(`${config.BACKEND_HOST_PORT}${config.HR_JOB_PORTAL_CREATE}`, jobData);
            if (response.data.status) {
                toast.success(response.data.message);
                setTitle('')
            } else {
                toast.error(response.data.message);

            }
        } catch (error) {
            console.error('Error posting job:', error);
            alert('Failed to post job.');
        }
    };

    return (
        <div className="job-portal">
            <h1>Post a Job</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="title">Job Title:</label>
                    <input
                        type="text"
                        id="title"
                        value={jobTitle}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Job Description:</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={jobDescription}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setDescription(data);
                        }}
                    />
                </div>
                <button type="submit" className="submit-btn">Post Job</button>
            </form>
        </div>
    );
};

export default JobPortal;
