import React, { createContext, useState } from 'react';

export const NameContext = createContext();

export const NameProvider = ({ children }) => {
  const [isNameSet, setIsNameSet] = useState(false);
  const [userName, setUserName] = useState('');

  return (
    <NameContext.Provider value={{ isNameSet, setIsNameSet, userName, setUserName }}>
      {children}
    </NameContext.Provider>
  );
};
