import React, { useState } from 'react';
import '../BlogPage.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imageCompression from 'browser-image-compression';
import config from '../../config/config';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }],
    ['link', 'image'],
    ['clean']
  ],
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet',
  'align',
  'link', 'image'
];

const News = () => {
  const [editorHtml, setEditorHtml] = useState('');

  const handleChange = (html) => {
    setEditorHtml(html);
  };

  const [image, setImage] = useState(null);
  const [ogImageUrl, setOgImageUrl] = useState(null);
  const [metaTags, setMetaTags] = useState({
    title: '',
    description: '',
    keywords: '',
    ogUrl: '',
    ogType: '',
    ogTitle: '',
    ogDescription: '',
    // ogImageAlt: '',
    // twitterDomain: '',
    // twitterUrl: '',
    twitterTitle: '',
    twitterDescription: '',
    twitterImageAlt: '',
    twitterSite: '',
    canonicalUrl: '',
  });

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    setOgImageUrl(file);
    const maxSizeMB = 1;

    if (file) {
      if (file.size / 1024 / 1024 > maxSizeMB) {
        toast.error('File size exceeds the 1MB limit. Please choose a smaller file.');
        return;
      }

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error compressing image:', error);
        toast.error('Error compressing image. Please try again.');
      }
    }
  };

  const handleAddField = (setter, values, newField) => {
    setter([...values, newField]);
  };

  const handleRemoveField = (setter, values, index) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setter(newValues);
  };

  const handleInputChange = (setter, values, index, field, value) => {
    const newValues = [...values];
    newValues[index][field] = value;
    setter(newValues);
  };



  const handleMetaTagChange = (e) => {
    const { name, value } = e.target;
    setMetaTags((prevMetaTags) => ({
      ...prevMetaTags,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!image) {
      toast.error('Please fill out all fields and upload an image.');
      return;
    }

    const formData = new FormData();
    formData.append('files', ogImageUrl);

    formData.append('ckEdit', JSON.stringify(editorHtml));

    Object.keys(metaTags).forEach((key) => {
      formData.append(key, metaTags[key]);
    });

    try {
      console.log('Sending form data to API:', formData);

      const response = await axios.post(`${config.BACKEND_HOST_PORT}${config.NEWS}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('API Response:', response);

      if (response.data.status) {
        toast.success(response.data.message);

        setImage(null);
        setMetaTags({
          title: '',
          description: '',
          keywords: '',
          ogUrl: '',
          ogType: '',
          ogTitle: '',
          ogDescription: '',
          // ogImageAlt: '',
          // twitterDomain: '',
          // twitterUrl: '',
          twitterTitle: '',
          twitterDescription: '',
          twitterImageAlt: '',
          twitterSite: '',
          canonicalUrl: '',
        });
      } else {
        toast.error('Failed to submit blog post. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting blog post:', error);
      toast.error('An error occurred. Please try again later.');
    }
  };



  return (
    <div className='admin_main'>
    <div className="blog-page-container">
      <h1>Create News</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-field">
          <label>Upload Image</label>
          <input type="file" onChange={handleImageUpload} />
          {image && <img src={image} alt="Preview" className="image-preview" />}
        </div>

        {/* {headFields.map((field, index) => (
          <div key={index} className="form-field-group">
            <div className="form-field">
              <label>Head Title {index + 1}</label>
              <input
                type="text"
                value={field.title}
                onChange={(e) => handleInputChange(setHeadFields, headFields, index, 'title', e.target.value)}
                className={field.title === '' ? 'empty' : ''}
              />
            </div>
            <div className="form-field">
              <label>Head Description {index + 1}</label>
              <textarea
                value={field.description}
                onChange={(e) => handleInputChange(setHeadFields, headFields, index, 'description', e.target.value)}
                className={field.description === '' ? 'empty' : ''}
              />
            </div>
            <button type="button" onClick={() => handleRemoveField(setHeadFields, headFields, index)}>
              Remove
            </button>
          </div>
        ))}
        <button type="button" onClick={() => handleAddField(setHeadFields, headFields, { title: '', description: '' })}>
          Add More Head Title and Description
        </button>

        {fields.map((field, index) => (
          <div key={index} className="form-field-group">
            <div className="form-field">
              <label>Subtitle {index + 1}</label>
              <input
                type="text"
                name="subTitle"
                value={field.subTitle}
                onChange={(e) => handleFieldChange(index, e)}
                className={field.subTitle === '' ? 'empty' : ''}
              />
            </div>
            <div className="form-field">
              <label>Sub Description {index + 1}</label>
              <textarea
                name="subDescription"
                value={field.subDescription}
                onChange={(e) => handleFieldChange(index, e)}
                className={field.subDescription === '' ? 'empty' : ''}
              />
            </div>
            <button type="button" onClick={() => handleRemoveField(setFields, fields, index)}>
              Remove
            </button>
          </div>
        ))}
        <button type="button" onClick={() => handleAddField(setFields, fields, { subTitle: '', subDescription: '' })}>
          Add More Subtitle and Sub Description
        </button> */}




        <div className="editor-wrapper">
          <ReactQuill
            value={editorHtml}
            onChange={handleChange}
            modules={modules}
            formats={formats}
          />
      
        </div>









        <div className="form-field-group">
          <div className="form-field">
            <label>Title</label>
            <input
              type="text"
              name="title"
              value={metaTags.title}
              onChange={handleMetaTagChange}
              className={metaTags.title === '' ? 'empty' : ''}
            />
          </div>
          <div className="form-field">
            <label>Description</label>
            <textarea
              name="description"
              value={metaTags.description}
              onChange={handleMetaTagChange}
              className={metaTags.description === '' ? 'empty' : ''}
            />
          </div>
          <div className="form-field">
            <label>Keywords</label>
            <input
              type="text"
              name="keywords"
              value={metaTags.keywords}
              onChange={handleMetaTagChange}
              className={metaTags.keywords === '' ? 'empty' : ''}
            />
          </div>
          <div className="form-field">
            <label>OG URL</label>
            <input
              type="text"
              name="ogUrl"
              value={metaTags.ogUrl}
              onChange={handleMetaTagChange}
              className={metaTags.ogUrl === '' ? 'empty' : ''}
            />
          </div>
          <div className="form-field">
            <label>OG Type</label>
            <input
              type="text"
              name="ogType"
              value={metaTags.ogType}
              onChange={handleMetaTagChange}
              className={metaTags.ogType === '' ? 'empty' : ''}
            />
          </div>
          <div className="form-field">
            <label>OG Title</label>
            <input
              type="text"
              name="ogTitle"
              value={metaTags.ogTitle}
              onChange={handleMetaTagChange}
              className={metaTags.ogTitle === '' ? 'empty' : ''}
            />
          </div>
          <div className="form-field">
            <label>OG Description</label>
            <textarea
              name="ogDescription"
              value={metaTags.ogDescription}
              onChange={handleMetaTagChange}
              className={metaTags.ogDescription === '' ? 'empty' : ''}
            />
          </div>
          {/* <div className="form-field">
            <label>OG Image Alt</label>
            <input
              type="text"
              name="ogImageAlt"
              value={metaTags.ogImageAlt}
              onChange={handleMetaTagChange}
              className={metaTags.ogImageAlt === '' ? 'empty' : ''}
            />
          </div>
          <div className="form-field">
            <label>Twitter Domain</label>
            <input
              type="text"
              name="twitterDomain"
              value={metaTags.twitterDomain}
              onChange={handleMetaTagChange}
              className={metaTags.twitterDomain === '' ? 'empty' : ''}
            />
          </div>
          <div className="form-field">
            <label>Twitter URL</label>
            <input
              type="text"
              name="twitterUrl"
              value={metaTags.twitterUrl}
              onChange={handleMetaTagChange}
              className={metaTags.twitterUrl === '' ? 'empty' : ''}
            />
          </div> */}
          <div className="form-field">
            <label>Twitter Title</label>
            <input
              type="text"
              name="twitterTitle"
              value={metaTags.twitterTitle}
              onChange={handleMetaTagChange}
              className={metaTags.twitterTitle === '' ? 'empty' : ''}
            />
          </div>

          <div className="form-field">
            <label>Twitter Description</label>
            <textarea
              name="twitterDescription"
              value={metaTags.twitterDescription}
              onChange={handleMetaTagChange}
              className={metaTags.twitterDescription === '' ? 'empty' : ''}
            />
          </div>

          <div className="form-field">
            <label>Twitter Card</label>
            <input
              type="text"
              name="twitterImageAlt"
              value={metaTags.twitterImageAlt}
              onChange={handleMetaTagChange}
              className={metaTags.twitterImageAlt === '' ? 'empty' : ''}
            />
          </div>
          <div className="form-field">
            <label>Twitter Site</label>
            <input
              type="text"
              name="twitterSite"
              value={metaTags.twitterSite}
              onChange={handleMetaTagChange}
              className={metaTags.twitterSite === '' ? 'empty' : ''}
            />
          </div>
          <div className="form-field">
            <label>Canonical URL</label>
            <input
              type="text"
              name="canonicalUrl"
              value={metaTags.canonicalUrl}
              onChange={handleMetaTagChange}
              className={metaTags.canonicalUrl === '' ? 'empty' : ''}
            />
          </div>
        </div>

        <button type="submit">Submit</button>
      </form>
    </div>
    </div>
  );
};

export default News;
