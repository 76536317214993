import React, { useEffect, useState } from "react";
import axios from 'axios';
import config from "../config/config";
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import Modal from './Modal'; 
import './Auth.css';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const LeadDownload = () => {
    const [leadHistoryMap, setLeadHistoryMap] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [leadsPerPage] = useState(10);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLeadId, setSelectedLeadId] = useState(null);
    const [supportReply, setSupportReply] = useState('');
    const [range, setRange] = useState(false);

    const leadDownload = async (allData = false) => {
        try {
            const params = allData ? {} : { fromDate, toDate };
            const response = await axios.get(`${config.BACKEND_HOST_PORT}${config.DOWNDLOAD_LEAD_SHEET}`, {
                params,
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'leads.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();

            console.log('Download successful');
        } catch (error) {
            toast.error('Record not Found.');
        }
    };

    const leadHistory = async () => {
        try {
            const history = await axios.post(`${config.BACKEND_HOST_PORT}${config.ULTRA_EXPO_HISTORY}`);
            if (history.data.status) {
                setLeadHistoryMap(history.data.data);
            } else {
                console.error('Error fetching history:', history.data.message);
            }
        } catch (error) {
            console.error('Error fetching history:', error);
            toast.error('Error fetching history.');
        }
    };

    useEffect(() => {
        leadHistory();
    }, []);

    const formatDate = (date) => {
        const zonedDate = toZonedTime(new Date(date), 'Asia/Kolkata');
        return format(zonedDate, 'dd/MM/yyyy');
    };

    const indexOfLastLead = currentPage * leadsPerPage;
    const indexOfFirstLead = indexOfLastLead - leadsPerPage;

    const filteredLeads = leadHistoryMap.filter(lead => {
        const term = searchTerm.toLowerCase();
        return (
            lead.name.toLowerCase().includes(term) ||
            lead.email.toLowerCase().includes(term) ||
            String(lead.whatsAppNumber).includes(term) ||
            formatDate(lead.createdAt).includes(term)
        );
    });

    const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const formatTime = (date) => {
        const zonedDate = toZonedTime(new Date(date), 'Asia/Kolkata');
        return format(zonedDate, 'HH:mm:ss');
    };

    const handleFromDateChange = (e) => setFromDate(e.target.value);
    const handleToDateChange = (e) => setToDate(e.target.value);
    const handleSearchTermChange = (e) => setSearchTerm(e.target.value);

    const openModal = (leadId) => {
        setSelectedLeadId(leadId);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSupportReply('');
    };

    const handleSubmitReply = async () => {
        try {
            const response = await axios.post(`${config.BACKEND_HOST_PORT}${config.SUPPORT_REPLY}`, {
                id: selectedLeadId,
                supportTeamReply: supportReply
            });
            if (response.data.status) {
                toast.success(response.data.message);
                closeModal();
                leadHistory(); // Refresh the lead history after updating
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error updating reply:', error);
            toast.error('Error updating reply.');
        }
    };

    const dateRange = () => {
       setRange(!range);
    }

    return (
        <div className='admin_main'>
        <div className="addown">
            <div className="container">
                <div className="download-options">
                    <div className="dwn-opt">
                       <div className="btn_groups">
                       <button onClick={dateRange} className="btns">Download with Date Range</button>
                        <div className={range ? "showran rangers": "rangers" }>
                            <div className="date-range">
                                <label>From Date:</label>
                                <input type="date" value={fromDate} onChange={handleFromDateChange} />
                            </div>
                            <div className="date-range">
                                <label>To Date:</label>
                                <input type="date" value={toDate} onChange={handleToDateChange} />
                            </div>
                            <button onClick={() => leadDownload(false)} className="btns">Download</button>
                        </div>
                        </div>
                        <button onClick={() => leadDownload(true)} className="btns">Download All Data</button>
                    </div>
                </div>
                <div className="search-options">
                    <input 
                        type="text" 
                        placeholder="Search by name, email, phone, or date" 
                        value={searchTerm} 
                        onChange={handleSearchTermChange} 
                    />
                </div>
                <div className="table-container">
                    <div className="table-responsive tabd">
                        <table>
                            <thead>
                                <tr>
                                    <th>NO</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Age</th>
                                    <th>District</th>
                                    <th>Interested</th>
                                    <th>Applied Date</th>
                                    <th>Applied Time</th>
                                    <th className="reply">Reply</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentLeads.length ? (
                                    currentLeads.map((data, index) => (
                                        <tr key={index}>
                                            <td>{indexOfFirstLead + index + 1}</td>
                                            <td>{data.name}</td>
                                            <td>{data.email}</td>
                                            <td>{data.whatsAppNumber}</td>
                                            <td>{data.age}</td>
                                            <td>{data.district}</td>
                                            <td>{data.interested}</td>
                                            <td>{formatDate(data.createdAt)}</td>
                                            <td>{formatTime(data.createdAt)}</td>
                                            <td className="reply">{data.supportTeamReply}</td>
                                            <td><button className="btns" onClick={() => openModal(data._id)}>Reply</button></td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="none">
                                        <td colSpan="9">No data available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination">
                        {Array.from({ length: Math.ceil(filteredLeads.length / leadsPerPage) }, (_, i) => (
                            <button key={i + 1} onClick={() => paginate(i + 1)}>
                                {i + 1}
                            </button>
                        ))}
                    </div>
                </div>
                {isModalOpen && (
                    <Modal
                        supportReply={supportReply}
                        setSupportReply={setSupportReply}
                        closeModal={closeModal}
                        handleSubmitReply={handleSubmitReply}
                    />
                )}
            </div>
        </div>
        </div>
    );
};

export default LeadDownload;
