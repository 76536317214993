import React, { useContext, useState } from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { randomID, getUrlParams } from './LiveFunction';
import { NameContext } from './NameContext';

export default function LiveExpo() {
//   const [userName, setUserName] = useState('');
//   const [isNameSet, setIsNameSet] = useState(false);
//   const {setIsNameContext} = useContext(NameContext);
const { isNameSet, userName, setUserName, setIsNameSet } = useContext(NameContext);
  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };

  const handleJoin = () => {
    if (userName.trim() !== '') {
      setIsNameSet(true);
  
    } else {
      alert('Please enter your name');
    }
  };

  // Get the room ID from URL or generate a new one
  const roomID = getUrlParams().get('roomID') || randomID(5);

  // Get the role from URL or set default as 'Host'
  let role_str = getUrlParams().get('role') || 'Host';
  const role = role_str === 'Host'
    ? ZegoUIKitPrebuilt.Host
    : role_str === 'Cohost'
      ? ZegoUIKitPrebuilt.Cohost
      : ZegoUIKitPrebuilt.Audience;

  // Define shared links for joining as co-host or audience
  let sharedLinks = [];
  if (role === ZegoUIKitPrebuilt.Host || role === ZegoUIKitPrebuilt.Cohost) {
    sharedLinks.push({
      name: 'Join as co-host',
      url: `${window.location.protocol}//${window.location.host}${window.location.pathname}?roomID=${roomID}&role=Cohost`,
    });
  }
  sharedLinks.push({
    name: 'Join as audience',
    url: `${window.location.protocol}//${window.location.host}${window.location.pathname}?roomID=${roomID}&role=Audience`,
  });

  // Generate Kit Token (replace with your appID and serverSecret)
  const appID = 2130682034; // Replace with your actual appID
  const serverSecret = "9469426a8f51d74da960b3118f3e6f37"; // Replace with your actual serverSecret
  const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, randomID(5), userName);

  // Function to start the meeting
  let myMeeting = async (element) => {
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    zp.joinRoom({
      container: element,
      scenario: {
        mode: ZegoUIKitPrebuilt.LiveStreaming,
        config: {
          role,
        },
      },
      sharedLinks,
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      {!isNameSet ? (
        <>
          <input
            type="text"
            placeholder="Enter your name"
            value={userName}
            onChange={handleNameChange}
            style={{ padding: '10px', fontSize: '16px', marginBottom: '20px',width:'50%' }}
          />
          <button onClick={handleJoin} style={{ padding: '10px 20px', fontSize: '16px' }}>Join</button>
        </>
      ) : (
        <div
          className="myCallContainer"
          ref={myMeeting}
          style={{ width: '100vw', height: '100vh' }}
        ></div>
      )}
    </div>
  );
}
